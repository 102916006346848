import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import { Icon, Text } from "@atoms";
import { m } from "framer-motion";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { navigate } from "gatsby";

const Frame = ({
  title,
  // index,
  // siblings,
  next,
  previous,
  // sections,
  hideControls,
}) => {
  const lang = useLang();

  const pages = {
    default: [
      {
        to: "/can-you-dream-it",
        ariaLabel: t("Go to the Can You Dream It? page"),
      },
      {
        to: "/can-you-build-it",
        ariaLabel: t("Go to the Can You Build It? page"),
      },
      {
        to: "/can-you-move-it",
        ariaLabel: t("Go to the Can You Move It? page"),
      },
    ],
    annualReport2022en: [
      {
        to: "/we-moved-money",
        ariaLabel: t("Go to the We Moved Money page"),
      },
      {
        to: "/we-moved-ideas",
        ariaLabel: t("Go to the We Moved Ideas page"),
      },
      {
        to: "/we-moved-together",
        ariaLabel: t("Go to the We Moved Together page"),
      },
    ],
  };

  return (
    <>
      <m.div
        className="absolute -left-14 bottom-0 top-14 z-[300] w-14 border-r-3 border-white opacity-0 md:left-0"
        initial={{ x: "-4.2rem", opacity: 0 }}
        animate={{ x: "0rem", opacity: 1 }}
        transition={{ ease: "circOut", delay: 0.6 }}
      >
        {/* close button + section nav */}
        <m.div className="sticky left-0 top-0">
          {!hideControls && (
            <AppLink className="inline-block p-4" ariaLabel="Go back" to="/">
              <Icon name="close" className="w-5 text-white" />
            </AppLink>
          )}

          {title && (
            <div className="mx-4 mx-4 mt-12 flex items-center justify-center border-b-2 border-white pb-4 lg:mt-24 xl:mt-36">
              <Text
                variant="sidewaysLabel"
                className="pointer-events-none text-white"
              >
                {title}
              </Text>
            </div>
          )}

          {!hideControls && (
            <div className="mt-4 flex flex-col items-center justify-center gap-1.5">
              {pages?.[process.env.GATSBY_CRAFT_SITE_HANDLE]?.map(link => (
                <AppLink
                  to={link?.to}
                  className="h-2 w-2 rounded-full border-2 border-white"
                  activeClassName="bg-white"
                  ariaLabel={link?.ariaLabel}
                />
              ))}
            </div>
          )}
        </m.div>
      </m.div>
      {!hideControls && (
        <div className="absolute bottom-0 left-0 right-0 z-0 z-[200] w-full md:left-14 md:left-14 md:w-[calc(100%-3.5rem)]">
          <m.div
            className="border-t-3 border-white"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "circOut", delay: 0.5 }}
          />
          <div className="flex flex-nowrap justify-between whitespace-nowrap text-white">
            <AppLink
              className={classNames(
                "flex items-center gap-3 px-6 py-7 md:w-48",
                {
                  "pointer-events-none opacity-0": !previous,
                }
              )}
              ariaLabel={t("Go to the previous chapter")}
              to={previous}
            >
              <Icon name="backwardsArrow" className="w-4 shrink-0" />
              <Text variant="button" className="hidden md:block">
                {t("Previous Chapter", lang)}
              </Text>
            </AppLink>
            <AppLink
              className="mx-auto inline-flex p-4"
              ariaLabel="Return home"
              to="/"
            >
              <Icon name="close" className="w-5 text-white" />
            </AppLink>
            <AppLink
              className={classNames(
                "flex items-center gap-3 whitespace-nowrap px-6 py-7 md:w-48",
                {
                  "pointer-events-none opacity-0": !next,
                }
              )}
              ariaLabel={t("Go to the next chapter")}
              to={next}
            >
              <Text variant="button" className="hidden md:block">
                {t("Next Chapter", lang)}
              </Text>
              <Icon name="forwardArrow" className="w-4 shrink-0" />
            </AppLink>
          </div>
        </div>
      )}
    </>
  );
};

Frame.defaultProps = {};

export default Frame;
